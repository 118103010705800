<template>
  <div>
    <div class="mt-20 st-data-entry st-group-list-table">
      <a-table
      :columns="columns"
      size="small"
      :data-source="standardizationGroup"
      :custom-row="customRow"
      :pagination="false"
      :locale="{ emptyText: $t('components.description.noStandardizationGroupsAddedYet') }">
        <template #standardizationStatus="standardizationStatus">
          <span>
            <a-tag
              :key="standardizationStatus"
              :color="standardizationStatus === 'marked_as_finished' ? 'green' : 'orange'">
              {{ standardizationStatus === 'marked_as_finished' ? $t('values.done').toUpperCase() : $t('values.pending').toUpperCase() }}
            </a-tag>
          </span>
        </template>
        <template #deleteGroup="groupId">
          <span>
            <div @click.stop="">
              <a-popconfirm
                :title="$t('components.notifications.areYouSureYouWantTodeleteGroup')"
                :ok-text="$t('values.yes')"
                :cancel-text="$t('values.no')"
                :disabled="isSupervisor"
                @confirm="deleteGroup(surveyId, groupId)">
                <a-icon type="delete" theme="filled" :style="{ color: !isSupervisor ? 'rgb(255 68 68)' : 'lightgrey', fontSize: '18px' }" :disabled="isSupervisor"/>
              </a-popconfirm>
            </div>
          </span>
        </template>
      </a-table>
    </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'StandardizationGroup',
  props: {
    current: {
      type: Number,
      required: true
    },
    steps: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      datasets: [],
      standardizationGroup: [],
      key: 'loadingStGroup'
    };
  },
  computed: {
    ...mapState({
      loadingCalls: state => state.standardizationGroups.loadingCalls,
      standardizationGroupMap: state => state.standardizationGroups.standardizationGroupMap,
      surveyId: state => state.survey.surveyId,
      lastUpdated: state => state.standardizationGroups.lastUpdated,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    columns() {
      return [
        {
          title: this.$t('components.titles.groupName'),
          dataIndex: 'title',
          key: 'group_name'
        },
        {
          title: `# ${this.$t('components.titles.ofEnumerators')}`,
          dataIndex: 'numberOfEnumerators',
          key: 'enumerators'
        },
        {
          title: this.$t('components.titles.standardizationStatus'),
          dataIndex: 'standardizationStatus',
          key: 'standardizationStatus',
          scopedSlots: { customRender: 'standardizationStatus' }
        },
        {
          dataIndex: 'id',
          key: 'groupId',
          scopedSlots: { customRender: 'deleteGroup' }
        }
      ];
    }
  },
  watch: {
    loadingCalls: {
      handler(newVal, oldVal) {
        if (newVal > 0 && !(oldVal > 0)) {
          this.$message.loading({ content: this.$t('components.notifications.loadingEnumerators'), key: this.key });
        }
        if (newVal != null && newVal === 0) {
          this.$message.loading({ content: this.$t('components.notifications.loadingEnumerators'), key: this.key, duration: 0.1 });
        }
      }
    },
    lastUpdated: {
      deep: true,
      handler() {
        this.loadStandardizationGroup();
      }
    }
  },
  mounted() {
    this.loadStandardizationGroup();
  },
  methods: {
    ...mapActions([
      'deleteTargetStandardizationGroupForSurvey'
    ]),
    loadStandardizationGroup() {
      this.standardizationGroup = [];
      Object.values(this.standardizationGroupMap).forEach(item => {
        item.numberOfEnumerators = item.enumerators?.length; // eslint-disable-line no-param-reassign
        item.key = item.id; // eslint-disable-line no-param-reassign
        this.standardizationGroup.push(item);
      });
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.$router.push({ name: 'Group', params: { groupSelectedRecord: record, groupId: record.id.toString(), activeTab: 'add-enumerators' } });
          }
        }
      };
    },
    deleteGroup(surveyId, groupId) {
      this.deleteTargetStandardizationGroupForSurvey({ surveyId, groupId });
    }
  }
};
</script>

<style lang="scss">
.st-group-list-table {
  .ant-table-tbody {
    background-color: #fff;
  }
  .ant-table-small .ant-table-content .ant-table-body {
    margin: 0;
  }
  .ant-table-thead {
    font-family: 'DINNextLTPro-Medium';
    font-weight: bold;
    background-color: #ececed;
    border-bottom: 1px solid #8e908f7c;
  }
  .ant-table-row {
    font-family: 'DINNextLTPro-Medium';
    border-bottom: 1px solid #8e908f7c;
    background-color: #ffffff;
  }
  table {
    width: 100%;
    table-layout: fixed;
  }
  .ant-table-row-cell-last {
    width: 5%;
  }
}
</style>


